<template>
  <v-input :prepend-icon="icon" class="mt-0 input-row" :label="label" :disabled="disabled" :hide-details="!hint" :hint="hint" :persistent-hint="!!hint">
    <template>
      <v-btn-toggle v-model="calculatedValue" mandatory :disabled="disabled" class="multiline-btns">
        <v-btn v-for="(item, idx) in items" :key="idx" :value="item.type || '_'" :small="small">
          {{ item.text }}
        </v-btn>
      </v-btn-toggle>
    </template>
  </v-input>
</template>

<script>
export default {
  name: "ToggleButtonInput",
  components: {
  },
  props: {
    value: { type: String, default: null },
    label: { type: String, default: null },
    hint: { type: String, default: null },
    icon: { type: String, default: null },
    items: { type: Array, default: null },
    small: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  data: function() {
    return {
      rendered: null,
    };
  },
  async mounted() {
      
  },
  methods: {
      
  },
  computed: {
    calculatedValue:{
      get () {
        console.log('/// toggle value', this.value, this.hint);
        return !this.value ? '_' : this.value
      },
      set (value) {
        let val = value == '_' ? null : value;
        console.log('/// toggle value changing to', val);
        this.$emit('input', val);
      }
    },      
  },
  watch: {
      
  }
};
</script>

<style lang="scss">
</style>