<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card>
      <v-card-title class="headline">
        Edit Organization
      </v-card-title>

      <div v-if="org">
        <v-alert v-if="error || !valid" type="error">{{error || 'There is one or more validation issues. Please check the form fields and correct any errors.' }}</v-alert>
        <v-form ref="form" v-model="valid" lazy-validation>

        <v-expansion-panels accordion tile class="mb-4">
        
        <!-- General -->
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-icon color="gray" class="shrink mr-4">fadl fal fa-info-circle</v-icon>
            General Options
          </v-expansion-panel-header>
          <v-expansion-panel-content>

            <p v-if="tenant.isDefault">
              Please read our <a href="https://sodisp.freshdesk.com/support/solutions/folders/77000137491" target="_blank">Frequently asked questions</a> on creating and managing events for tips and tricks.
            </p>
            <v-text-field 
              v-model="org.name" 
              :rules="nameRules" 
              label="Organization name (required)" 
              hint="The name of your organization"
              prepend-icon="fa-pencil-alt"
              required>
          </v-text-field>
          
          <v-input prepend-icon="fa-lock" class="mt-0 input-row" label="Require verification to join" hide-details>
            <template>
              <v-btn-toggle v-model="calculatedVerificationMethod" mandatory class="multiline-btns">
                <v-btn value="_" small>
                  None
                </v-btn>
                <v-btn value="REG_DATA" small>
                  Registration<br/>Data
                </v-btn>
              </v-btn-toggle>
            </template>
          </v-input>
          <div v-if="!org.verification" class="ml-8 text-muted">This organization is open for anyone to join. Everyone who has the link can join.</div>
          <div v-if="org.verification === 'REG_DATA'" class="ml-8 text-muted">Manage registration data and/or upload Excel files to configure who may join this organization.</div>

          <ToggleButtonInput v-model="org.datacenter_id" label="Default datacenter" icon="fa-globe" :items="siteData.datacenters" :disabled="!orgUtil.isProPlan()" class="mt-4" hint="For newly created events only" />
          <a href="https://sodisp.freshdesk.com/en/support/solutions/articles/77000530381-choosing-your-datacenter-location" class="font-s ml-8" target="_blank">Read more on choosing a datacenter location</a>

          </v-expansion-panel-content>
        </v-expansion-panel>
        

        <!-- Design -->
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-icon color="gray" class="shrink mr-4">fadl fal fa-fill-drip</v-icon>
            Design
            <v-spacer/>
            <span v-if="org.logo && org.img" class="shrink mr-4 green--text">Configured</span>
            <span v-else class="shrink mr-4 red--text">Not Yet Uploaded</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>Customize the design of your organization page. This will also be used as the default for newly created events within this organization.</p>
            <h3>Artwork</h3>
            <v-row>
              <v-col cols="12" md="6" class="py-0">
                <p>Logo (optional, square ~ 500 x 500 px)</p>
                <EventImageUploadForm :group-id="org.id" :img="org.logo" type="logo" />
              </v-col>

              <v-col cols="12" md="6" class="py-0">
                <p>Header image at the top of the organization page (optional, landscape format ~ 1150 x 300 px)</p>
                <EventImageUploadForm :group-id="org.id" :img="org.img" type="image" />
              </v-col>
            </v-row>
            <v-dialog v-if="org.img" max-width="800px">
              <template v-slot:activator="{ on }">
                <v-btn outlined color="primary" v-on="on"><v-icon small class="mr-2">fa fa-eye</v-icon> View Preview</v-btn>
              </template>
              <v-card>
                <GroupHeader v-if="org.img" :group="org" :title="org.name" />
              </v-card>
            </v-dialog>
            <p v-else class="font-style--italic">Upload at least a header image to see a preview of the organization header.</p>

            <h3>Primary Color</h3>
            <p>Configure your primary brand color. Will be used throughout your organization page. Make sure to pick a color with good contrast.</p>
            <v-row>
              <v-col cols="6">
                <v-text-field 
                  v-model="org.color" 
                  label="Brand Color" 
                >
                  <template v-slot:append>
                    <v-menu v-model="showColorPickerMenu" right :close-on-content-click="false">
                      <template v-slot:activator="{ on }">
                        <div :style="swatchStyle" v-on="on" />
                      </template>
                      <v-card>
                        <v-card-text class="pa-0">
                          <v-color-picker v-model="colorForBinding" show-swatches mode="hexa" flat />
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </template>                        
                </v-text-field>
              </v-col>
            </v-row>

          </v-expansion-panel-content>
        </v-expansion-panel>
        


        </v-expansion-panels>

        <v-card-text>   
          <v-btn class="mt-8 mb-8" block :disabled="!valid" color="primary" :loading="$store.getters.isLoading" @click="submit">Save</v-btn>
          <v-snackbar v-model="savedSnackbar" top color="success" :timeout="6000">
            Hooray, your organization is saved!
          </v-snackbar>
          <!-- <v-alert v-if="validationError" class="mt-4" type="error">There is at least one validation issue, please check all fields marked in red.</v-alert> -->
          <v-alert v-if="error || !valid" class="mt-4" type="error">{{error || 'There is one or more validation issues. Please check the form fields and correct any errors.' }}</v-alert>
        </v-card-text>   
        </v-form>
      </div>      
    </v-card>
    
    <SupportButton/>
  </div>      
</template>



<script>
import { mapGetters } from "vuex";
import profileService from "@/services/profileService";
import orgManagerService from "@/services/orgManagerService";
import EventImageUploadForm from "@/components/EventImageUploadForm";
import DateWithTimeZonePicker from "@/components/DateWithTimeZonePicker";
import DistanceTextArea from "@/components/DistanceTextArea";
import SupportButton from '@/components/SupportButton.vue'
import MarkdownEditor from '@/components/MarkdownEditor.vue'
import ToggleButtonInput from '@/components/ToggleButtonInput.vue'
import GroupHeader from "@/components/GroupHeader.vue";
import OrgUtil from "@/util/orgUtil";
import { EventBus } from '@/plugins/eventbus.js';
import { DateTime } from 'luxon'
import siteData from '@/data/site.json'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Profile",
  components: {
    DateWithTimeZonePicker,
    DistanceTextArea,
    SupportButton,
    MarkdownEditor,
    ToggleButtonInput,
    EventImageUploadForm,
    GroupHeader,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      siteData: siteData,
      valid: true,
      savedSnackbar: false,
      showColorPickerMenu: false,
      error: null,
      org: null,
      nameRules: [
        v => !!v || "Please enter the organization name",
      ],
    };
  },
  async mounted() {
    await this.loadData();
    
    EventBus.$on('login-state-change', async user => {
      await this.loadData();
    });
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        this.error = null;
        try {
          await this.orgUtil.save();

          this.$router.push({ name: 'orgmanagerView', params: { id: this.orgUtil.org.id }});
        }
        catch (ex) {
          console.error('Error saving', ex);
          this.error = 'Unexpected error during saving, please verify all data and try again.';
        }
      }
    },

    async getOrg(id) {
      var response = await orgManagerService.get(id);
      this.org = response.data;
    },

    async loadData() {
      if (this.user) {
        await this.getOrg(this.$route.params.id);
      }
    },

  },
  computed: {
    orgUtil() {
      return new OrgUtil(this, this.org);
    },

    colorForBinding: {
      get() {
        console.log('GET', this.org.color);
        if (!this.org.color || this.org.color.length == 0) {
          return "#FFFFFF";
        }
        return this.org.color;
      },
      set(value) {
        console.log('SET', value);
        if (value && value.length > 7) {
          value = value.substring(0, 7).toUpperCase();
          if (value === '#FFFFFF') {
            value = null;
          }
        }
        this.org.color = value;
        this.$forceUpdate();
      }
    },
    swatchStyle() {
      const { org, showColorPickerMenu } = this
      return {
        backgroundColor: org.color,
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        border: 'solid 1px #ccc',
        borderRadius: showColorPickerMenu ? '50%' : '4px',
        transition: 'border-radius 200ms ease-in-out'
      }
    },
    calculatedVerificationMethod:{
      get () {
        return !this.org || !this.org.verification ? '_' : this.org.verification
      },
      set (value) {
        this.org.verification = value === '_' ? null : value;
      }
    },

    breadcrumbItems() {
      return !this.org ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: 'Organizations', exact: true, to: { name: 'orgmanager' } },
        { text: this.org.name, exact: true, to: { name: 'orgmanagerView', params: {id: this.org.id}} },
        { text: 'Edit', disabled: true },
      ];
    },

    ...mapGetters({
      user: "user"
    })
  },
  watch: {
  },
};
</script>
<style lang="scss">
</style>

